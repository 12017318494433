import React,{useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//service 
import {CurrencyDecimalFormat} from '../../helper';
import style from './ewallet.module.scss';

import {Filter} from './';
function HistoryTable({ContentData,paginationchange,loader}) {
    const {t}  = useTranslation();
    const Currency       = useSelector(state => state.curr);
    const [state, setState] = useState({data: []});
    const columns = [
        {
          name: t('Common.description').toUpperCase(),
          selector:row=> row.description,
          width : "30%"
        },
        {
          name: t('Common.amount').toUpperCase(),
          selector:row=> row.amount,
          cell: (row) => <span className={`${row.classnameamount} ${style.badge}`}>{Currency.currentCurr} {Number(row.amount).toFixed(Currency.precision)}</span>
        },
        {
          name: t('Common.transactionFee').toUpperCase(),
          selector:row=> row.transaction_fee,
          sortable: true,
          cell: (row) => <span className={`${style.balanceAmount} ${style.badge}`}> {row.transaction_fee === "NA"?row.transaction_fee:`${Currency.currentCurr} ${Number(row.transaction_fee).toFixed(Currency.precision)}`} </span>
        },
        {
            name: t('Common.notes').toUpperCase(),
            selector: row=>row.transaction_note,
            width : "30%",
            cell : (row) => <span dangerouslySetInnerHTML={{__html:row.transaction_note}} className={style.description}></span>
          },
        {
            name: t('Common.transactionDate').toUpperCase(),
            selector:row=> row.date,
            sortable: true
        },
      ];

    const {data} = state;
    useEffect(()=>{
        let NewData = ContentData.data.map((resData)=>{
            if(resData.type==="credit"){

                return {
                    classnameamount : style.credit,
                    amount : resData.amount,
                    transaction_fee : resData.transaction_fee,
                    description : resData.description,
                    date : resData.date,
                    transaction_note:resData.transaction_note   
                }
            }else if(resData.type==="debit"){
                return {
                    classnameamount : style.debit,
                    amount : resData.amount,
                    transaction_fee : resData.transaction_fee,
                    description : resData.description,
                    date : resData.date,
                    transaction_note:resData.transaction_note
                }
            }
            return null
        })
        setState(prev => ({
            ...prev,
            data:  NewData
          }));
    },[ContentData]);

    // onchange page 
    const handlePageChange = inPage =>{
        paginationchange(
            inPage,
            ContentData.perPage,
            '',
            '',
            ContentData.direction,
            '',''
        );
    }
    const handlePerRowsChange = (perPage, inPage) =>{
        paginationchange(
            inPage,
            perPage,
            '',
            '',
            ContentData.direction,
            '',
            ''
        )
    }
    const handleOnSort = ({ selector }, sortDirection) => {
        paginationchange(
            ContentData.inPage,
            ContentData.perPage,
            '',
            '',
            sortDirection,
            selector,
            '',
            )
    }

    const filterType = [
        { key: t('Common.debit'), value: "user_debit" },
        { key: t('Common.credit'), value: "user_credit" }
    ]

    //reset handler
    const resetData = () =>{

        loader.EarningsTable.reset = true
       
       
        paginationchange(
            ContentData.inPage,
            ContentData.perPage,
            '',
            '',
            '',
            '',
            '',
            )
    }

    //finter handler
    const filterHandler = (start,end,keys='') =>{

        loader.EarningsTable.search = true


        paginationchange(
            ContentData.inPage,
            ContentData.perPage,
            start,
            end,
            '',
            '',
            keys[0]?keys[0].value:'',
            )
    }
    return (
        <div>
            <Filter 
                filterHandler={filterHandler}
                type={filterType}  
                resetHandler={resetData}
                isSingle={true}
                loader={loader}
            />
            <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                progressPending={ContentData.pending}
                progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                responsive
                paginationTotalRows={ContentData.totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noDataComponent={t('Common.noDataRecordsToDisplay')}
                onSort={handleOnSort}
            />
        </div>
    )
}

export default HistoryTable
