import React from 'react';
 import style from '../profile/profile.module.css';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import Nophoto from '../../../assets/images/nophoto/no_photo.jpg';
import { IMG_URL } from '../../../configuration/apiconfig';
import moment from 'moment';
import ReactPlayer from "react-player"


const VimoContent = (props) => {
    const {t} = useTranslation();
    const history = useHistory();
    const currency = useSelector(state => state.curr);

    const viewPackage = id => {
        history.push(`/package_details/${id}`);
    }
    const OpenLink = link =>{
        window.open(link,'_blank','height=450,width=550');
    }

    return (
        console.log(props),
        <div className={style.container}>
            {/* <AlertBs 
                varient={props.alert.varient}
                show={props.alert.show}
                message={props.alert.message}
                close={props.closeAlert}
            />
            <AddonNotification />
            {
                props.cart && props.cart.length>0 &&
                <CartTable 
                cart={props.cart}
                />
            } */}
            <Row>
                {
                    
                    props.data.map((item, index) => (
                         <Col key={index} md={3} lg={3}>
                            <Card className={style.card}>
                                <Card.Body className={`${style.panel}`}>
                                    <div className={`text-center`}>
                                        {item.link&&
                                       <ReactPlayer
                                       url={item.link}
                                       playing={false}
                                       volume={1}
                                       width="30"
                                       height="30vh"
                                     />
                                        }
                                    </div>
                                    <div className={style.itemTitle}>
                                        <h4>{item.name}</h4>
                                    </div>
                                
                                 
                             

                                </Card.Body>
                                <Card.Footer className={style.noPadder}>
                                    <Row className={style.noGutter}>
                                        <Col md={6} lg={6} style={{'padding': 0}}>
                                            <div className={`${style.wrapper} ${style.br} text-center`}>
                                            <Button variant="info" className={`${style.btnAddon} ${style.btnAddonInfo}`} onClick={()=>{
                                                OpenLink(item.link)
                                            }}>
                                                <i className="fa fa-vimeo"></i>
                                                Open in Vimeo
                                                {/* {t('Button.addToCart')} */}
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))


                }
            </Row>
        </div>
    );
}
 
export default VimoContent;