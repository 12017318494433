import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import style from './tools.module.scss';
import DownloadService from '../../../service/tools/download';
import { useHistory } from 'react-router-dom';
import { BASE, IMG_URL } from '../../../configuration/apiconfig';
import Spinner from '../../shared/Spinner';
import AppInfoservice from '../../../service/common/Appinfo';
import { addNotify, addMail } from '../../../store/action';
import { useDispatch } from 'react-redux';



const DownloadContent = () => {
    const history = useHistory();
    const [state, setState] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const service = new DownloadService();
        setLoading(true)
        service.getDocument().then(res => {
            if (res.status) {
                setState(res.data.documen_data)
                setLoading(false)
            } else {
                setLoading(false)
                if (res.error.code === 1002) {
                    history.push('/logout')
                } else if (res.error.code === 1057) {
                    history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message: 'permissionDenied'
                        }
                    })
                }
            }
        })
    }, [history])
    useEffect(() => {
        const app = new AppInfoservice();
        app.getNotification().then(res => {
            if (res.status) {
                console.log(",,,,", res.data.notification_details)
                // // dispatch(addMail(res.data.mail_details.mail_count, res.data.mail_details.mail_data))
                dispatch(addNotify(res.data.notification_details))
            }
        })


    }, []);
    const download = (e) => {
        console.log(e)
        var fn = e.match(/([^/])+/g);
        fn = fn[fn.length - 1];
        fetch(e)
            .then(response => {
                console.log("--------", response)
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = fn;
                    a.click();
                });

            });

    }
    const docIcon = id => {

        if (id === "1") {
            return <img style={{ width: '100%' }} src={BASE + '/uploads/images/logos/doc/xls.jpg'} alt="" />
        } else if (id === '2') {
            return <img style={{ width: '100%' }} src={BASE + '/uploads/images/logos/doc/doc.jpg'} alt="" />
        } else if (id === '3') {
            return <img style={{ width: '100%' }} src={BASE + '/uploads/images/logos/doc/jpg.jpg'} alt="" />
        }
    }
    return (
        loading ?
            <Spinner /> :

            <div className={style.downloadConatiner}>
                <div className={style.userDowmloadSection}>
                    {
                        state && state.length == 0 ?
                            <div className={`${style.NodataImage}`} style={{ textAlign: 'center' }}>
                                <img src={IMG_URL + "logos/no-datas-found.png"} alt="nodata" style={{ width: '50%' }} />
                            </div>
                            : (
                                state.map((items, index) => (
                                    console.log(items),
                                    <div className={style.content} key={index}>
                                        <div>
                                            {docIcon(items.ctgry)}
                                        </div>
                                        <div className={style.snddiv}>
                                            <div>{items.file_title} </div>
                                            <div>{items.uploaded_date}</div>
                                            <div>{items.doc_desc}</div>
                                        </div>
                                        <div>
                                            <div className={style.placeright}>
                                                <Button variant="success">
                                                    {/* <a download="foo.txt" target="_blank" href="http://example.org/assets/avatar.png">download</a> */}
                                                    <a
                                                        href={items.doc_file_name} target='_blank' className={style.linkcoloor}
                                                        download
                                                        onClick={e => download(items.doc_file_name)}
                                                    > 
                                                   <i className="fa fa-download" />
                                                        download</a>
                                                         
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )
                    }
                </div>
            </div>
    );
}

export default DownloadContent;