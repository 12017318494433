import React,{setState}from 'react';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {FormInputControl,DropDownBs,DatePickers} from '../common';
// import {DatePickers} from './';
function FormsControl(props) {



    console.log("from contact details",props)



    const {t} = useTranslation();

    

    //return components 
    const Components = type =>{
        if(type === 'text'){
            return <FormInputControl 
                    label={t('profile.'+props.data.code)}
                    required={props.data.required}
                    inputProps={{
                        id:props.data.code,
                        name:props.data.code,
                        type:'text', 
                        value:props.data.value??'',
                        disabled:props.status,
                        onChange:e=>props.change(e,props.data.required)
                    }}
                    error={{
                        error : props.data.error,
                        field : props.data.errorField
                    }}
                />
        }else if(type === 'select'){
            return <DropDownBs 
                        label={t('profile.'+props.data.code)}
                        required={props.data.required}
                        options={{
                            data:props.data.options,
                            value : 'value',
                            label : 'code'
                        }}
                        DropdownProps={{
                            disabled:props.status,
                            onChange:(e)=>{props.dropdownChange(e.target.value,props.data)},
                            value:props.data.value ?? ''
                        }}
                        />
                        
        }else if(type === 'date'){
            return <DatePickers 
                        disabled={props.status}
                        start={props.data.value}
                        datechange={props.dateChange}
                        id={props.data.code}
                        label={t('profile.'+props.data.code)}
                        error={{
                            error : props.data.error,
                            field : props.data.errorField
                        }}
                    />
        }
    }
    return (
        Components(props.data.type)
    )
}

export default FormsControl
