// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo3.infinitemlmdemo.com/gerald/backoffice/api/";
// const IMG_URL = "https://demo3.infinitemlmdemo.com/gerald/uploads/images/";
// const BASE = "https://demo3.infinitemlmdemo.com";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://admin.fixxit.at/backoffice/api/";
const IMG_URL = "https://admin.fixxit.at/uploads/images/";
const BASE = "https://admin.fixxit.at";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY = "39b7bf45-759f-4701-9782-4d28bf74bb3d"
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY
}


