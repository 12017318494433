import { React, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './dashboard.module.scss';
import { Link } from 'react-router-dom';
import Links from './Links';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import CountdownTimer from '../common/CountdownTimer';
import { useCountdown } from '../common/useCountdown';
import { Popover, OverlayTrigger } from 'react-bootstrap';
function WebinarList(props) {


    const [state, setState] = useState({
        sevendaysinseconds: 7 * 24 * 60 * 60 * 1000,
        joineddatenowinseconds: new Date(props.profile && props.profile.join_date).getTime(),
        loading: props.lodervalue,
        dateTimeAftersevendays: ''

    })


    useEffect(() => {
        if (!state.loading) {
            setState(prevstate => ({
                ...prevstate,
                dateTimeAftersevendays: state.sevendaysinseconds + state.joineddatenowinseconds
            }))
        }

    }, []);

    function Clipboard_CopyTo(value) {
        var tempInput = document.createElement("input");
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }

    const { t } = useTranslation()

    const popover = (
        <Popover id="popover-basic" varient="success">
            <Popover.Title as="h3" >{t('Dashboard.linkCopied')}</Popover.Title>
        </Popover>
    );

    const [days, hours, minutes, seconds] = useCountdown(new Date(state.sevendaysinseconds + new Date(props.profile && props.profile.join_date).getTime()));
    return (
        <>

            <div className={style.dashboard_pannel_box}>
                {props.lodervalue &&
                    <Row>
                        <Col xl={4} lg={4} md={12}>
                            <div className={style.webinar_textbox_sub_head}><Skeleton width={50} /></div>
                            <div className={style.webinar_textbox_dv}>
                                <Skeleton width={100} />
                            </div>
                        </Col>
                    </Row>
                }

                {props.webinar &&
                    <Row>
                        <Col xl={4} lg={4} md={12}>
                            <div className={style.webinar_sec}>
                                <h4 class="m-t-none m-b  font-thin-bold">
                                    {t('Dashboard.webinar_links')}
                                </h4>
                                {props.webinar.map((value, index) => {
                                    console.log(value)

                                    return (
                                        <>
                                            <div className={style.webinar_textbox_sub_head}>{value.heading}</div>
                                            <div className={style.webinar_textbox_dv}>
                                                <input type="text" value={value.webinar_link} />
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                                                    <a onClick={() => Clipboard_CopyTo(value.webinar_link)} className={style.webinar_copy_btn}>COPY</a>
                                                </OverlayTrigger>

                                            </div>

                                        </>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>

                }
            </div>
        </>

    )
}
export default WebinarList;
