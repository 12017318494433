
import React,{useState,useRef,useEffect} from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Multiselect } from "multiselect-react-dropdown";
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import style from '../../components/ewallet/ewallet.module.scss';
 import ReactToPrint from "react-to-print";


//import UserEarningsPrintView from'./UserEarningsPrintView'
import ReportsService from '../../../service/reports';



function CommissionReport({filterHandler,type,resetHandler,isSingle,ContentData,loader,...props}) {
    const {t} = useTranslation();
    const componentRef = useRef();
    const [state, setState] = useState({
        selectedValues : [],
        start:moment().startOf('month'),
        end: moment(),
        label : moment().startOf('month').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
    });
    const {
        start, 
        end,
        label,
        selectedValues
    } = state;
    const service = new ReportsService();

    useEffect(() => {
       // getData();
       service.CommisionReport().then((res) => {
        if (res.status) {   
            console.log("gdgdg",res)
           
        } else {
            //props.history.push('/logout');
        }
    })
    }, []);

    //customselect options
    const options = {
        Today: [moment().toDate(), moment().toDate()],
        'This Week': [
            moment().startOf('week'),
            moment().endOf('week').toDate()
        ],
        'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate()

        ],
        'This Year': [
            moment().startOf('year').toDate(),
            moment().endOf('year').toDate()
        ],
    }

    //datepicker select
    const handleCallback = (start, end) => {
        setState(prev => ({
            ...prev,
            start : start,
            end   :  end,
            label: start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
          }));
    };

    // multi select css components
    let styles = {
        multiselectContainer: { // To change css for multiselect (Width,height,etc..)
            minWidth: "10%",
            maxWidth: "max-content",
            display : "inline-block"
          },
        inputField: { // To change input field position or margin
            margin: "5px",
            width:"40px",
        },
        chips: { // To change css chips(Selected options)
          background : "rgb(228, 228, 228)",
          color : "rgb(88, 102, 110)",
          borderRadius : "10px",
          height : "20px"
        },
        optionContainer: { // To change css for option container 
            boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
            border : "none"
        },
        searchBox: { // To change search box element look
            padding:0,
            marginRight : "5px",
            paddingLeft : "5px"
          },
        option: { // To change css for dropdown options
          color: "black" 
        },
      }
    //on select the multi select
    const  selecthandler = (e) =>{
        setState(prev => ({
            ...prev,
            selectedValues: e,
            label : moment().startOf('month').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
          }));
    }

//remove selected items

const removeHandler = (e) => {
    setState(prev => ({
        ...prev,
        selectedValues: e,
        label : moment().startOf('month').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
      }));
}

    //reset button click
    const  resetValue = () =>{
        setState(prev => ({
            ...prev,
             selectedValues: [],
             start:moment().startOf('month'),
             end: moment(),
            label : moment().startOf('month').format('MMMM D, YYYY') + ' - ' + moment().format('MMMM D, YYYY')
          }));
          resetHandler();
    }

    const submitHandler = () =>{
        filterHandler(start.format('YYYY-MM-DD'),end.format('YYYY-MM-DD'),selectedValues);
    }

    
    return (
        <div className={style.filterSection}>
            <p>Commision reports work</p>
            {/* <DateRangePicker 
                initialSettings={{
                    startDate: start.toDate(),
                    endDate: end.toDate(),
                    ranges: options ,
                }}
                onCallback={handleCallback}
                >
                <div
                className={style.DateRangePicker}
                >
                    <i className="fa fa-calendar"></i>&nbsp;
                    <span>{label}</span> <i className="fa fa-caret-down"></i>
                </div>
            </DateRangePicker>
            <Multiselect 
                    singleSelect={isSingle}
                    options={type}
                    className="col-md-3 col-sm-12"
                    showCheckbox={true}
                    style={styles}
                    onSelect={selecthandler}
                    onRemove={removeHandler}
                    displayValue="key"
                    avoidHighlightFirstOption={true}
                    placeholder={t('Common.type')}
                    selectedValues={selectedValues}
                    // hidePlaceholder={selectedValues.length===0?false:true}
                />
                <div className={style.filterButton}>
                    <Button variant="info" className={style.padding5} onClick={submitHandler}>

                  
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            /> 
                 

                        {t('Button.submit')}
                    </Button>
                    </div> */}
        </div>
    )
}

export default CommissionReport


