import React from 'react';
import {useTranslation} from 'react-i18next';
import { Form } from 'react-bootstrap';
import style from './auth.module.scss';

const Forms = ({ error, type, name,change,value,blurhandler,from}) => {
    const {t}       = useTranslation();
    return (
        <Form.Group controlId={name}>
            <Form.Control 
                type={type} 
                name={name}
                value={value}
                placeholder={t('Login.PlaceHolder.'+name)} 
                className={`
                    ${style.loginForms}
                    ${error ?`${style.loginFormsHasError}form-control is-invalid`: ""}
                    `} 
                onChange={change}
                onBlur={()=>{
                    if(from=='forgot'){
                        if(name === 'userName'||name === 'Email'){
                            blurhandler(name)
                            }
                    }
                    
                }}
                    />
            {/* error message */}
            <Form.Text className={`
                ${error
                    ? style.isInvalid
                    : "d-none"}
                `}>
    {t('Login.Error.'+error)}
            </Form.Text>
        </Form.Group>
    );
};

export default Forms;