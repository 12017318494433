import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory } from 'react-router';
import ProfileService from '../../../service/profile';
import { SearchContent } from '../../components/tools';
import VimoContent from '../../components/tools/VimoContent';
import NewsService from '../../../service/tools/news';
import { IMG_URL } from '../../../configuration/apiconfig';



function Vimo(props) {
    let service = new NewsService();
    let history = useHistory();
    const [state, setState] = useState({
        data: [],
        loader: true,
        name: '',
        serachloader: '',
        resetloader: ''
    })


    const getVideos = (name = '') => {

        service.vimoVideos(name)
            .then(res => {
                if (res.status) {
                    console.log(res)
                    setState(prevState => ({
                        ...prevState,
                        data: res.data,
                        loader: false
                    }));
                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout');
                    } else if (res.error.code === 1057) {
                        props.history.push({
                            pathname: '/dashboard',
                            state: {
                                error: true,
                                message: 'permissionDenied'
                            }
                        })
                    }
                }
            })
    }

    useEffect(() => {
        getVideos()

    }, []);


    const searchClick = (e) => {
        e.preventDefault();
        setState(prev => ({
            ...prev,
            serachloader: true
        }))

        getVideos(state.name)
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                serachloader: false
            }))

        }, 3000);
    }
    const resetClick = (e) => {
        e.preventDefault();
        setState(prev => ({
            ...prev,
            resetloader: true
        }))
        getVideos();
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                resetloader: false
            }))

        }, 3000);
        setState(prevState => ({
            ...prevState,
            name: ''
        }));

    }
    const changeHandler = (e) => {

        const { value } = e.target
        console.log(value)
        setState(prev => ({
            ...prev,
            name: value
        }))
    }


    const { t } = props;
    if (state.loader) {
        return <Spinner />
    }

    else {
        return (
            <>
                <div className="h-100">
                    <Helmet>
                        <title>{companyName.companyName} | {t("Sidemenu.vimeo_video")}</title>
                    </Helmet>
                    <PageTitle
                        title="vimeo_video"
                        buttonOn={false} />

                    <div style={{ padding: "15px" }}>
                        <SearchContent
                            searchClick={searchClick}
                            changeHandler={changeHandler}
                            resetClick={resetClick}
                            {...state}
                        />
                        {state.data.length == 0 ?
                            <div style={{ textAlign: 'center' }}>
                                <img src={IMG_URL + "logos/no-datas-found.png"} alt="nodata" style={{ width: '50%' }} />
                            </div> :
                            <VimoContent
                                {...state} />
                        }
                    </div>

                </div>
            </>
        )

    }

}
export default withTranslation()(withRouter(Vimo));