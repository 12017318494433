const { BASE_URL } = require("../../configuration/apiconfig");

export default class ResetPasswordService {


    // https://demo3.infinitemlmdemo.com/enson/backoffice/login/valid_user
    async getuserName(resetkey){
        console.log(resetkey)
        const response = await fetch(BASE_URL+'auth/reset_password?resetkey='+resetkey, {     //url
            method: 'GET',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : '39b7bf45-759f-4701-9782-4d28bf74bb3d'
                                  
            },
        });
        const res = await response.json();
        return res;
    }
    async resetPassword(formData){
        const response = await fetch(BASE_URL+'auth/password_reset', {     //url
            method: 'POST',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : '39b7bf45-759f-4701-9782-4d28bf74bb3d'
            },
            body : JSON.stringify(formData)
        });
        const res = await response.json();
        return res;
    }

    async getCaptcha(username){
          //url
        const response = await fetch(BASE_URL+'auth/forget_password?username='+username, {     //url
            method: 'GET',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : '39b7bf45-759f-4701-9782-4d28bf74bb3d',
        
            }
        })
        if(response.status === 200){
            return response.blob()
        }else{
            const res = await response.json();
            return res;
        }
    }
    async getTranuserName(resetkey){
        console.log(resetkey)
        const response = await fetch(BASE_URL+'auth/reset_tran_password?resetkey='+resetkey, {     //url
            method: 'GET',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : '39b7bf45-759f-4701-9782-4d28bf74bb3d'
                                  
            },
        });
        const res = await response.json();
        return res;
    }
    async resetTranPassword(formData){
        const response = await fetch(BASE_URL+'auth/tran_password_reset', {     //url
            method: 'POST',                 //method
            headers : {
                'Content-Type'  : 'application/json',
                'api-key'       : '39b7bf45-759f-4701-9782-4d28bf74bb3d'
            },
            body : JSON.stringify(formData)
        });
        const res = await response.json();
        return res;
    }
    
}
