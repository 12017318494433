import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import SellerContent from '../../components/profile/SellerContent';
import Spinner from '../../shared/Spinner';
import shoppingService from '../../../service/shopping';
import { withRouter } from 'react-router-dom';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory } from 'react-router';
import ProfileService from '../../../service/profile';
import { IMG_URL } from '../../../configuration/apiconfig';


function SellerList(props) {
    let service = new ProfileService();
    let history = useHistory();



    const [state, setState] = useState({
        data: [],
        loader: true,



    })


    useEffect(() => {
        service.getSellerList()
            .then(res => {
                if (res.status) {
                    console.log(res)
                    setState(prevState => ({
                        ...prevState,
                        data: res.data,
                        loader: false
                    }));


                } else {
                    if (res.error.code === 1002) {
                        props.history.push('/logout');
                    } else if (res.error.code === 1057) {
                        props.history.push({
                            pathname: '/dashboard',
                            state: {
                                error: true,
                                message: 'permissionDenied'
                            }
                        })
                    }
                }
            })



    }, []);





    //add to cart



    const { t } = props;
    if (state.loader) {
        return <Spinner />
    }

    else {
        return (



            <>

                <div className="h-100">
                    <Helmet>
                        <title>{companyName.companyName} | {t('Sidemenu.seller_list')}</title>
                    </Helmet>
                    <PageTitle
                        title="seller_list"
                        buttonOn={false} />
                    {/* <div className={style.MainContainer}> */}

                    {state.data.length == 0 ?
                        <div style={{ textAlign: 'center' }}>
                            <img src={IMG_URL + "logos/no-datas-found.png"} alt="nodata" style={{ width: '50%' }} />
                        </div> :
                        <SellerContent
                            {...state}

                        />
                    }

                    {/* </div> */}
                </div>
            </>
        )

    }

}
export default withTranslation()(withRouter(SellerList));