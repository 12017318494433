import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  // Route,Switch
} from 'react-router-dom';
import './App.scss';
import './style/customstyle.css';
import RouteMap from './route';
import { createBrowserHistory } from 'history';
  
import {BASE_URL} from '../configuration/apiconfig'


function getFaviconEl() {
  return document.getElementById("favicon");
}
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logo: ''
    }
  }
  componentDidMount() {
    fetch(
      BASE_URL+'common/app_info', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'api-key': '39b7bf45-759f-4701-9782-4d28bf74bb3d'
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          logo: json.data.company_info.favicon
        });
        this.handleGoogle();
      })
  }
  handleGoogle() {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = "https://admin.fixxit.at/uploads/images/logos/" + this.state.logo
  };
  render() {
    return (
      <Router>
        <RouteMap />
      </Router>
    )
  }
}
export default App;
