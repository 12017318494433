import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import Language from '../components/common/Language';
import Currency from '../components/common/Currency';
import style from './commonstyle.module.scss';
import { connect } from 'react-redux';
import logo from '../../assets/images/dashboard/logo.png';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { IMG_URL } from '../../configuration/apiconfig';
import AppInfoservice from '../../service/common/Appinfo';
// import AppInfoservice from '';




//notification materialUI
// import NewspaperIcon from '@mui/icons-material/Newspaper';
// import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
// import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
// import PaymentIcon from '@mui/icons-material/Payment';

// import NavNotification from './NavNotification';
//custum lang dropsown lang button
const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (

  <span
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ cursor: 'pointer' }}
  >
    {/* Render custom icon here */}
    {children}
  </span>
));
function mapStateToProps(state) {
  const { Mail } = state
  const { Notification } = state
  const { Menu } = state
  return { Mail: Mail, Notification: Notification, Menu: Menu }

}
const MainNavbar = (props) => {
  let pathto;

  const [state, setState] = useState({
    icononly: false
  })
  const [logo, setLogo] = useState()

  function toggleOffcanvas() {

    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  function toggleRightSidebar() {
    document.querySelector('.tp_msg_btn').classList.toggle('show');
  }
  function toggleIcononly() {
    document.body.classList.toggle('sidebar-icon-only');
    setState({ icononly: !state.icononly })

  }

  useEffect(() => {
    document.querySelector('#dropdown-basic').classList.remove('btn');
    document.querySelector('#dropdown-basic').classList.remove('btn-primary');
    const app = new AppInfoservice();
     app.Appinfo().then(res => {
        if (res.status) {
          setLogo(res.data.company_info.logo);
        }
    })

  }, [])



  const viewmail = (evt) => {
    evt.preventDefault();
    props.history.push('/mailbox#inbox')
  }


  const handler = (evt) => {

    evt.preventDefault();
    // props.history.push('/mailbox#inbox')
  }

  const viewNotification = (items, item) => {
    pathto = item === 'document_count' ? '/downloadDocument' : item === 'pin_count' ? '/epin' : item === 'news_count' ? '/news' : '/payout';
    props.history.push(pathto)
  }

  const { Mail, t } = props;
  const { Notification } = props;
  const { Menu } = props;
  let name_title;

  const mailHandler = (evt) => {

    evt.preventDefault();
    props.history.push('/mailbox')
  }
  const mainClick = () => {
    const urlData = Menu.Menu.find(element => element.title === 'register');
    if (urlData.url) {
      window.location.href = urlData.url;
    }

  }

  const total_count = Notification.pin_count + Notification.document_count + Notification.news_count + Notification.payout_count;

  return (
    <div className={style.main_contant_sec}>
      <div className={style.topbar_section}>
        <div className={style.logo_sec}>
          <div className={style.menubartogglebtn} onClick={() => { toggleIcononly() }}><span></span></div>
          <a href={"dashboard"}><div className={style.logo_cn}><img src={IMG_URL + 'logos/'+logo} alt=""></img></div></a>
        </div>
        <div className={style.top_notification_sec} style={{ width: "100px", float: "left", display: "flex", height: "51px" }}>

          <Dropdown style={{ left: '-30px' }}>
            <Dropdown.Toggle variant="Secondary">
              <Currency />
            </Dropdown.Toggle>
          </Dropdown>

          <Dropdown style={{ left: '-40px' }}>
            <Dropdown.Toggle variant="Secondary" >
              <Language ismainlayout={true} />
            </Dropdown.Toggle>

          </Dropdown>
        </div>
        <div className={style.top_notification_user_sec}>
          <div className={style.top_notification_sec}>

            <Dropdown className={`${style.top_notification_ico} ${style.tp_msg_btn} ${style.materialiconsoutlined}`}>
              <Dropdown.Toggle variant="Secondary"  >
                <MarkunreadOutlinedIcon  style={{color:'#fff'}}/>
                {
                  Mail.count > 0 &&
                  <span className={style.notification_count}>{Mail.count}</span>
                }
              </Dropdown.Toggle>

              <Dropdown.Menu className={style.notification_msg_box} >
                {
                  Mail.count > 0 ?
                    <div className={style.mailDropDown}>

                      <Dropdown.Item className={style.notification_msg_box_cnt_row} href="!#" onClick={evt => evt.preventDefault()}>
                        <p className="mb-0 font-weight-medium float-left">{t('Common.youHaveNewMail', { count: Mail.count })} </p>
                        <span onClick={mailHandler} className={`badge badge-pill badge-primary float-right ${style.viewBadge}`}>{t('Common.viewAll')}</span>
                      </Dropdown.Item>
                      <div className="notification_msg_box_cnt">
                        {
                          Mail.mail_data.map((item, index) => (
                            [
                              <Dropdown.Item className={style.notification_msg_box_cnt_row} href="!#" onClick={viewmail} key={index}>
                                <div className="preview-item-content">
                                  <span className={`${style.image} mr-2`}>
                                    <img src={item.image} alt="" />
                                  </span>
                                  <span className="msg_box_cnt_t">
                                    {item.username}<br />
                                    {item.mailadsubject}<br />
                                    <small>{item.mailadiddate}</small>
                                  </span>
                                </div>
                              </Dropdown.Item>

                            ]
                          ))
                        }
                      </div>
                    </div> :
                    <Dropdown.Item className={style.notification_msg_box_cnt_row} href="!#" onClick={evt => evt.preventDefault()}>
                      <strong>{t('Common.youHaveNoNewNotification')}</strong>
                    </Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className={`${style.top_notification_ico} ${style.tp_not_btn} ${style.materialiconsoutlined}`}>
              <Dropdown.Toggle variant="Secondary" >
                <span><NotificationsOutlinedIcon  style={{color:'#fff'}} /></span>
                {total_count > 0 &&
                  <div className={style.notification_count}>{total_count}
                  </div>}
              </Dropdown.Toggle>

              <Dropdown.Menu className={style.notification_msg_box}>
                {
                  total_count > 0 ?
                    <div className={style.mailDropDown}>
                      <Dropdown.Item className={style.notification_msg_box_cnt_row} onClick={evt => evt.preventDefault()} >
                        <p className="mb-0 font-weight-medium float-left">{total_count + " "}{t('Common.MissedNotification')} </p>
                        {/* <span className={`badge badge-pill badge-primary float-right ${style.viewBadge}`}>{t('Common.viewAll')}</span> */}
                      </Dropdown.Item>
                      <div className="notification_msg_box_cnt">
                        {
                          Object.keys(Notification).map((item, index) => (
                            <div key={index}>

                              {(Notification[item] > 0) &&
                                <Dropdown.Item className={style.notification_msg_box_cnt_row} onClick={(items) => { viewNotification(items, item); }}>
                                  {/*                               
                              onClick={(item)=>{
                                  viewNotification(item);
                                  handler();}} key={i} */}
                                  <div className="preview-item-content">
                                    <div>{t('Common.you_have')} {Notification[item]}  {name_title = item === 'document_count' ? 'Documents' : item === 'pin_count' ? t('Common.epin_request_confirmed') : item === 'news_count' ? 'new news' : 'payout'}</div>
                                  </div>
                                </Dropdown.Item>
                              }
                            </div>


                          ))}
                      </div>

                    </div> :
                    <Dropdown.Item className={style.notification_msg_box_cnt_row} href="!#" onClick={evt => evt.preventDefault()} >
                      {/* <p className="mb-0 font-weight-medium float-left">{t('Common.youHaveNewMail',{count:'no'})} </p> */}
                      {/* <span className="badge badge-pill badge-primary float-right">View all</span> */}
                      <strong>{t('Common.youHaveNoNewNotification')}</strong>
                    </Dropdown.Item>
                }
              </Dropdown.Menu>
              {/* <Dropdown.Item className={style.notification_msg_box_head} href="#/action-1" onClick={evt => evt.preventDefault()}>{t('Common.viewAll')}</Dropdown.Item> */}
            </Dropdown>
          </div>
          <div className={style.top_user_acc_sec}>
            <Dropdown className={` ${style.dropdowntoggle1}`}>
              <Dropdown.Toggle variant="Secondary" id="dropdown-basic" style={{color:'#fff'}}>
                <span>
                  <img className="img-xs rounded-circle" src={props.image} alt="Profile" />
                </span> {props.userName}

              </Dropdown.Toggle>

              <Dropdown.Menu className={style.dropdownmenu}>
                <Dropdown.Item className={style.dropdownitem} onClick={() => { props.history.push('/profile') }} >{t('Common.profile')}</Dropdown.Item>
                <Dropdown.Item className={style.dropdownitem} onClick={() => mainClick()}>{t('Common.signUp')}</Dropdown.Item>
                <Dropdown.Item className={style.dropdownitem} onClick={() => props.history.push('/logout')}>{t('Sidemenu.logout')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* <div className={`btn-group ${style.dropdowntoggle1}`}>
                  <button type="button" className=" " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>U</span>User Name
                  </button>
                  <div className={style.dropdownmenu}>
                    <a className={style.dropdownitem} href="#">Profile</a>
                    <a className={style.dropdownitem} href="#">Another action</a>
                    <a className={style.dropdownitem} href="#">Something else here</a>
                    <div className={style.dropdownitem}></div>
                    <a className={style.dropdownitem} href="#">Logout</a>
                  </div>
                </div> */}
          </div>
        </div>
      </div>

    </div>
  )



}


export default
  withTranslation()
    (connect(mapStateToProps)(withRouter(MainNavbar)));