import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Accordion, Col, Row, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IMG_URL } from '../../../configuration/apiconfig';
import style from './mail.module.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AppInfoservice from '../../../service/common/Appinfo';
import { addNotify, addMail } from '../../../store/action';
import { useParams } from "react-router-dom";
function ViewMail(props) {

    const params = useParams()

    const [state, setState] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const service = new AppInfoservice();
        setState(Object.values(props.data));

        service.getNotification().then(res => {
            if (res.status) {
                dispatch(addMail(res.data.mail_details.mail_count, res.data.mail_details.mail_data))
                dispatch(addNotify(res.data.notification_details))
            }
        })
    }, [props.data]);

    const { t } = useTranslation();
    console.log( params.type)
    return (
        <div>
            {
                params.type == 'user' ?
                    <>
                        <div className={style.headerContent}>
                            <h4>{state[0] && [
                                state[0].subject ??
                                state[0].mailadsubject
                            ]}</h4>
                        </div>
                        <div className={style.ViewMailContent}>
                            {
                                state.length === 0 &&
                                <Accordion className={style.mailPanel}>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" className={style.panelHeading}>
                                            <div className={style.panelHeadingWrapper}>
                                                <Row>
                                                    <Col md={1}>
                                                        <SkeletonTheme color="#F0FFFF" highlightColor="#444">
                                                            <Skeleton duration={2} delay={1} circle={true} height={50} width={50} />
                                                        </SkeletonTheme>
                                                    </Col>
                                                    <Col md={11}>
                                                        <SkeletonTheme color="#F0FFFF" highlightColor="#444">
                                                            <p><Skeleton width={200} duration={2} delay={1} /></p>
                                                            <p><Skeleton width={200} duration={2} delay={1} /></p>
                                                        </SkeletonTheme>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card>
                                </Accordion>
                            }
                            {
                                state.map((e, index) => (

                                    <Accordion className={style.mailPanel} key={index}>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="0" className={style.panelHeading}>
                                                <div className={style.panelHeadingWrapper}>
                                                    <Row>
                                                        <Col md={1}>
                                                            <Image src={IMG_URL + "profile_picture/mail_pro.png"} className={style.profileImage} />
                                                        </Col>
                                                        <Col md={11}>
                                                            {e.is_sent_mail ?
                                                                <>
                                                                    <span>{t('Common.me')}</span>
                                                                    <span style={{ color: "#fda341" }}> ({t('Common.sent')}) </span><br />
                                                                    <span>{e.date ?? e.mailadiddate}</span>
                                                                </>
                                                                :
                                                                <>
                                                                    <span>{e.fullname}</span>
                                                                    <span style={{ color: "#fda341" }}> ({t('Common.received')})</span><br />
                                                                    <span>({e.user_name ?? e.to})</span><br />
                                                                    <span>{e.date ?? e.mailadiddate}</span>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0" className={`accordian`}>


                                                <Card.Body className={style.mailMessage} dangerouslySetInnerHTML={{ __html: e.msg ?? e.msg }} />

                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                ))
                            }
                            {
                                (state.length > 0 && props.reply) &&
                                <div className={style.replyContent}>
                                    <div className={style.replyMailFixed}>
                                        <span>{t('Common.clickHereTo')} </span>
                                        <span className="text-danger" style={{ cursor: "pointer" }} onClick={() => { props.replyMessage(state && state[state.length - 1].id) }}> {t('Common.reply')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </> :
            

                    <>
                    
                

                  
                        <div className={style.headerContent}>
                            <h4>{ props.data.contact_name && props.data.contact_name + " contacted you"}</h4>

                        </div>
                        <div className={style.ViewMailContent}>

                            {
                                <Accordion className={style.mailPanel}>

                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" className={style.panelHeading}>
                                            <div className={style.panelHeadingWrapper}>
                                                <Row>
                                                    <Col md={1}>
                                                        <Image src={IMG_URL + "profile_picture/mail_pro.png"} className={style.profileImage} />
                                                    </Col>
                                                    <Col md={11}>
                                                        {props.data['is_sent_mail'] ?
                                                            <>
                                                                <span>{t('Common.me')}</span>
                                                                <span style={{ color: "#fda341" }}> ({t('Common.sent')}) </span><br />
                                                                <span>{props.data['date'] ?? props.data['date']}</span>
                                                            </>
                                                            :
                                                            <>
                                                            {console.log(props.data['contact_email'])}
                                                                <span>{props.data['contact_email'] ?? props.data['contact_email']}</span>
                                                                <span style={{ color: "#fda341" }}> { props.data['contact_name'] &&(t('Common.received'))}</span><br />
                                                                <span> {props.data['contact_name'] &&  (props.data['contact_name'])}</span><br />
                                                                <span>{props.data['date'] ?? props.data['date']}</span>
                                                            </>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0" className={`accordian`}>


                                            <Card.Body className={style.mailMessage} dangerouslySetInnerHTML={{ __html: props.data['contact_info'] ?? props.data['contact_info'] }} />

                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                            }

                        </div>
                    </>

            }


        </div>
    )
}
export default ViewMail
