import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class NewsService{
    key =localStorage.getItem('apiKey')
    //get the referral members
    async getNews(){
        const response = await fetch(BASE_URL+'tools/all_news', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const allnews = await response.json();
        return allnews;
    }
    //get the vieww news data
    async viewNews(id){
        const response = await fetch(BASE_URL+'tools/view_news?news_id='+id, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const newsData = await response.json();
        return newsData ;
    }
    async vimoVideos(name){
        const response = await fetch(BASE_URL+'profile/vimeo_video?search_query='+name, {     //url
            method: 'GET',                 //method
            headers : {
                'api-key'       : this.key,
                'access-token'  : UserStore.key,
                'content-type'  : "application/json"
            },
        })
        const res = await response.json();
        return res;
    }
}