import React, { useEffect ,useState} from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar ,Button} from 'react-bootstrap';


import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';



function Scrollbutton(props) {

    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };
      
      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
      
      window.addEventListener('scroll', toggleVisible);
    return (
        <Button onClick={scrollToTop} className={style.scrolltop}  style={{display: visible ? 'inline' : 'none'}}>
        <ArrowUpwardIcon 
         />
       </Button>

    )
}

export default Scrollbutton