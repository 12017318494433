
import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class ReportsService{
    key =localStorage.getItem('apiKey')
    async CommisionReport(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'report/commission_report', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    async PayoutReport(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'report/commission_report', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    async fundTransfer(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'ewallet/fund_transfer', {
                method: 'POST',
                headers : {
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    'Accept-Language': '',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    async fundTransfer(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'ewallet/fund_transfer', {
                method: 'POST',
                headers : {
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    'Accept-Language': '',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
}

