import React from 'react';
import style from './profile.module.scss';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import Nophoto from '../../../assets/images/nophoto/no_photo.jpg';
import { IMG_URL } from '../../../configuration/apiconfig';
import moment from 'moment';


const SellerContent = (props) => {
    const {t} = useTranslation();
    const history = useHistory();
    const currency = useSelector(state => state.curr);

    const viewPackage = id => {
        history.push(`/package_details/${id}`);
    }

    return (
        console.log(props),
        <div className={style.container}>
            {/* <AlertBs 
                varient={props.alert.varient}
                show={props.alert.show}
                message={props.alert.message}
                close={props.closeAlert}
            />
            <AddonNotification />
            {
                props.cart && props.cart.length>0 &&
                <CartTable 
                cart={props.cart}
                />
            } */}
            <Row>
                {
                    props.data.map((item, index) => (
                        console.log(item),
                         <Col key={index} md={3} lg={3}>
                            <Card className={style.card}>
                                <Card.Body className={`${style.panel}`}>
                                    <div className={`text-center ${style.content}`}>
                                        {item.doc_file_name&&
                                        <>
                                     <a href={item.website_url}  className={`${style.wrapper}`}  target='_blank' title={item.name}>
                                    <i class="fa fa-chain" style={{color:'white',textAlign:'center'}} aria-hidden="true"></i>

                                        <img  src={IMG_URL+"/sellers/"+item.doc_file_name} className={style.cartItemImage} alt="No Image"/>
                                      </a>
                                  </>
                                        }
                                    </div>
                                    <div className={style.itemTitle}>
                                        <h4>{item.name}</h4>
                                    </div>
                                    <div className={style.textCenter}>
                                        <small>
                                            { moment(item.uploaded_date).format('MMM DD, YYYY')}
                                        </small>
                                    </div>
                                    <div className={style.textCenter}>
                                        <small className={style.price}>
                                        {item.description}
                                        </small>
                                    </div>
                             

                                </Card.Body>
                                <Card.Footer className={style.noPadder}>
                                    {/* <Row className={style.noGutter}>
                                        <Col md={6} lg={6} style={{'padding': 0}}>
                                            <div className={`${style.wrapper} ${style.br} text-center`}>
                                            <Button variant="info" className={`${style.btnAddon} ${style.btnAddonInfo}`} onClick={()=>{
                                                props.addToCart(item.id,item.product_name)
                                            }}>
                                                <i className="fa fa-shopping-cart"></i>
                                                {t('Button.addToCart')}
                                            </Button>
                                            </div>
                                        </Col>

                                        <Col md={6} lg={6} style={{'padding': 0}}>
                                            <div className={`${style.wrapper} ${style.br} text-center`}>
                                            <Button onClick={() => viewPackage(item.id)} variant="success" className={`${style.btnAddon} ${style.btnAddonSuccess}`}>
                                                <i className="fa fa-eye"></i>
                                                {t('Button.moreDetails')}
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row> */}
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))


                }
            </Row>
        </div>
    );
}
 
export default SellerContent;